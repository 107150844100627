import React, { Component } from 'react';

export default class Resume extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="resume">

                    {/* Education ----------------------------------------------- */}
                    <div className="row education">
                        <div className="three columns header-col">
                            <h1><span>Education</span></h1>
                        </div>
                        <div className="nine columns main-col">
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>Dalhousie University</h3>
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.dal.ca/faculty/computerscience.html">
                                            Master's Degree in Electronic Commerce
                                        </a>
                                        <span>•</span> <em className="date">70% completed</em></p>
                                    <p>
                                        The Dalhousie Master of Electronic Commerce
                                        (MEC) is the first of its kind in Canada, and is the only Canadian e-commerce
                                        program to feature delivery through collaboration between three faculties.
                                        The program blends coursework, research, and business experience to offer a
                                        commanding overview of e-commerce and e-business.
                                    </p>
                                </div>
                            </div> {/* item end */}
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>State University of Campinas</h3>
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://ic.unicamp.br/en/graduacao/engenharia-da-computacao/">Computer Engineering</a>
                                    </p>
                                    <p>
                                        State University of Campinas - UNICAMP - is a leading Brazilian public
                                        university that has attained international recognition for excellence through
                                        its innovative approach to higher education.
                                    </p>
                                </div>
                            </div> {/* item end */}
                        </div> {/* main-col end */}
                    </div> {/* End Education */}

                    {/* Certifications ----------------------------------------------- */}
                    <div className="row education">
                        <div className="three columns header-col">
                            <h1><span>Certifications</span></h1>
                        </div>
                        <div className="nine columns main-col">

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/428871b4-d580-4632-8909-b38ad1e3dbe5">
                                            Hybrid Cloud Knowledge Leader 2.0
                                        </a>
                                        <span>•</span> <em className="date">2025</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/07fbf8ae-c046-4413-b9ac-abef5ddf6244">
                                            Red Hat Cloud Services: Seller
                                        </a>
                                        <span>•</span> <em className="date">2025</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/c48018a6-1466-4994-9bf4-7acf5094e4c5">
                                            Red Hat OpenShift: Seller
                                        </a>
                                        <span>•</span> <em className="date">2025</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/697bb817-3744-47f8-8672-867316090931">
                                            Red Hat Portfolio: Foundational
                                        </a>
                                        <span>•</span> <em className="date">2025</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/8f486d63-d98b-469b-924b-86ca90994c44">
                                            IBM Delivery Central Platform Foundations
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/ed1eb101-4476-4e9f-8439-a5cb1e9fb462">
                                            AWS Certified Machine Learning Engineer – Associate
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/f76e8939-3d0b-4ab3-b109-00703255e0d3">
                                            AWS Certified Machine Learning Engineer - Associate Early Adopter
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/6cf0024b-3fcb-4bc9-86ef-3dfa238ac941">
                                            IBM AI Associate Data Scientist
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/53db71da-ccd0-4dab-99ff-81915e1178f6">
                                            AWS Certified AI Practitioner
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/b030634e-cfca-4666-836f-3495aebf60d8">
                                            AWS Certified AI Practitioner Early Adopter
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/5df9eee0-b9ba-4880-84d9-41db16467ba8">
                                            IBM Generative AI Foundations
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/5baa71f0-76cf-4111-aa23-3e7e0066aff5">
                                            AWS Partner: Generative AI Essentials
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/dd1f3809-828a-4b1b-88e2-2110f7c16524">
                                            Gen AI for Business Leaders
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/a251dcc6-2b3d-4aae-9f79-86defda0cd77">
                                            watsonx.ai Technical Sales Intermediate
                                        </a>
                                        <span>•</span> <em className="date">2024</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/3747b92c-c7f2-48fb-ae97-8f0fef8485c4">
                                            Banking Insights and Solutions (Silver)
                                        </a>
                                        <span>•</span> <em className="date">2022</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/3f5ab0c1-2046-4387-be7c-4160c20d65d9/public_url">
                                            AWS Certified Developer - Associate
                                        </a>
                                        <span>•</span> <em className="date">2022</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="images/certificates/kafka-certificate.png">
                                            Confluent Certified Developer for Apache Kafka
                                        </a>
                                        <span>•</span> <em className="date">2021</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="images/certificates/master-in-demand-professional-soft-skills-linkedin.png">
                                            Master In-Demand Professional Soft Skills
                                        </a>
                                        <span>•</span> <em className="date">2021</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.coursera.org/account/accomplishments/specialization/83C888UX4SMQ">
                                            Developing Applications with Google Cloud Platform Specialization
                                        </a>
                                        <span>•</span> <em className="date">2020</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.coursera.org/account/accomplishments/verify/227MKFLXB8HF">
                                            Google Cloud Platform Big Data and Machine Learning Fundamentals
                                        </a>
                                        <span>•</span> <em className="date">2020</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="images/certificates/mulesoftware-certified-dev.png">
                                            MuleSoft Certified Developer
                                        </a>
                                        <span>•</span> <em className="date">2019</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://courses.cognitiveclass.ai/certificates/83721c5072504e539f3fb9c43e539f74">
                                            IBM Watson Analytics 101
                                        </a>
                                        <span>•</span> <em className="date">2018</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.youracclaim.com/badges/03a2d7ec-477d-408b-a4eb-a6997632087c">
                                            IBM Cognitive Practitioner
                                        </a>
                                        <span>•</span> <em className="date">2017</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.credly.com/badges/a982c0d3-1779-4660-88c6-cffc44bed3ff/public_url">
                                            IBM Cloud Essentials
                                        </a>
                                        <span>•</span> <em className="date">2017</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.youracclaim.com/badges/a982c0d3-1779-4660-88c6-cffc44bed3ff">
                                            IBM Bluemix Essentials
                                        </a>
                                        <span>•</span> <em className="date">2017</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.youracclaim.com/badges/0f1b6316-502d-4646-8933-1e25ac403fbf">
                                            IBM Blockchain Essentials for Developers
                                        </a>
                                        <span>•</span> <em className="date">2017</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <p className="info">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://www.youracclaim.com/badges/3533fd24-7f59-42d8-b290-630d5f6a245c">
                                            IBM Data Science Foundations - Level 1
                                        </a>
                                        <span>•</span> <em className="date">2017</em>
                                    </p>
                                </div>
                            </div> {/* item end */}

                        </div> {/* main-col end */}
                    </div> {/* End Certifications */}

                    {/* Work ----------------------------------------------- */}
                    <div className="row work">
                        <div className="three columns header-col">
                            <h1><span>Work</span></h1>
                        </div>
                        <div className="nine columns main-col">

                            {/* IBM */}
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>IBM</h3>
                                    <p className="info">Software Developer <span>|</span> Bedford, Canada <span>|</span> <em className="date">since 2016</em></p>
                                    <p>
                                        Worked for the Department of National Defence (Canada) developing an open-source
                                        code for a proof of concept using Solace PubSub+, Spring Boot, Java, Docker,
                                        MySQL, React, Vite and Axios. This open-source project is available at GitHub:&nbsp;
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://github.com/jrodolfo/solace">
                                            Solace Suite
                                        </a>.
                                    </p>
                                    <p>
                                        Participated in the IBM WatsonX Challenge 2024, working with Retrieval Augmented
                                        Generation (RAG). This advanced AI framework enhances large language models by
                                        retrieving and integrating information from external knowledge bases.
                                        Used Jupyter Notebook, Python, Milvus vector database, watsonx-ai, and watsonx-data.
                                    </p>
                                    <p>
                                        Worked for PNC on an event-driven architecture built on Confluent Kafka, by
                                        developing Kafka Streaming applications. Created 7-hour knowledge transfer
                                        videos to teach new Kafka Streaming developers the techniques.
                                    </p>
                                    <p>
                                        Aided Northern Trust with the implementation of a proof of concept for the back
                                        end in Java via the creation of a Spring Boot application which listens to
                                        Apache Kafka topics and follows specified steps to process the messages.
                                    </p>
                                    <p>
                                        Utilized Node.js and JavaScript to construct an API for mobile devices,
                                        on behalf of an US Law Enforcement agency, to enable access to a
                                        DB2 IBM database deployed across the IBM Cloud.
                                    </p>
                                    <p>
                                        Enterprise Rent-A-Car:
                                        developed an app (proof of concept) for cars using the Driver First Framework
                                        by General Motors (GM), which received excellent client survey results.
                                    </p>
                                    <p>
                                        Spruce Meadows, Alberta:
                                        worked as an Android Developer on a mobile fan engagement application for
                                        directions in Spruce Meadows which included the implementation of Java calls to
                                        external APIs. The app is available at Google Play:&nbsp;
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://play.google.com/store/apps/details?id=com.mobile.sprucemeadowsapp"
                                        >
                                            Spruce Meadows Fan Experience
                                        </a>
                                    </p>
                                    <p>
                                        Panorama - System for Public Health:
                                        maintained and enhanced an enterprise scale tool coded in Java as part of
                                        Panorama public health system, used for managing preventative and crisis aspects
                                        of the Public Health jurisdictions, such as outbreaks.
                                    </p>
                                    <p>
                                        Achieved the IBM Super Learner status from 2019 to 2024 due to the application
                                        of a growth mindset and by putting learning at the forefront of the career.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            {/* CGI */}
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>CGI</h3>
                                    <p className="info">Software Developer <span>|</span> Halifax, Canada <span>|</span> <em className="date">2013 - 2016</em></p>
                                    <p>
                                        Implemented a user exit for Informatica Master Data Management on behalf of CIBC
                                        using Java with calls to a RESTful API, and added new features to a remote
                                        banking application from Business Banking Technology, which uses Java, Java
                                        Server Faces, Java Portlets, and RESTful web services.
                                    </p>
                                    <p>
                                        Implemented a JIRA based enterprise scale tool for management teams
                                        at CGI Corporate, and constructed a Java application to load Professional
                                        Services Automation information into a Master Data file.
                                    </p>
                                    <p>
                                        Implemented feeds and integration for back-end solutions on behalf
                                        of Deutsche Bank to support highly complex pricing platforms and
                                        trading systems.
                                    </p>
                                    <p>
                                        Improved upon a remote banking application for TD Bank by enabling branch
                                        employees to search for customer credit card statements and authorizations.
                                    </p>
                                    <p>
                                        Customized the tools inside CGI Corporate to achieve a uniform approach for
                                        global delivery and to support common metrics to improve upon productivity
                                        and performance.
                                    </p>
                                    <p>
                                        Developed a proof of concept for Deutsche Bank using ElasticSearch and JMeter
                                        to improve upon the search performance of trading history.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            {/* Mariner Partners */}
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>Mariner Partners</h3>
                                    <p className="info">Software Developer <span>|</span> Halifax, Canada <span>|</span> <em className="date">2011 - 2012</em></p>
                                    <p>
                                        Aided Bell Aliant with the development of the FibreOP front-end website.
                                        Created a mediation module for Medavie Blue Cross using WebSphere Integration
                                        Developer.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            {/* NTT Data */}
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>NTT Data</h3>
                                    <p className="info">Software Developer <span>|</span> Halifax, Canada <span>|</span> <em className="date">2008 - 2011</em></p>
                                    <p>
                                        Designed and developed the Pason API, a system which grants access to drilling
                                        data via RESTful web services; upheld and enriched a Blackberry application to
                                        enable the user to view the real-time drilling information on active wells.
                                    </p>
                                    <p>
                                        Upgraded the enrolment component of a system for TIAA that offers users a clear
                                        understanding of both the risks and benefits of financial products; executed a
                                        code review to support business analyses and documentation; employed Struts to
                                        construct the front-end in order to support upgrades to the system.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            {/* Motorola */}
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>Motorola</h3>
                                    <p className="info">Software Developer <span>•</span> <em className="date">June 2006 - November 2006 (6 months)</em></p>
                                    <p>
                                        Worked on the project Warehouse Management System, and was responsible for
                                        the Information Manager module which was developed using Perl and Java.
                                        This module is used by production managers to have control in warehouse
                                        and production lines, supporting their decisions.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            {/* MRO Software - IBM */}
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>MRO Software - IBM</h3>
                                    <p className="info">Software Developer <span>|</span> São Paulo, Brazil <span>|</span> <em className="date">2004 - 2006</em></p>
                                    <p>
                                        Utilized Maximo, an MRO/IBM framework, to create industry specific
                                        modules. Extended functionality across all layers of the framework via the use
                                        of Java. Deployed the development into WebLogic.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>Bireme - World Health Organization</h3>
                                    <p className="info">Software Developer <span>|</span> São Paulo, Brazil <span>|</span> <em className="date">1999 - 2004</em></p>
                                    <p>
                                        Implemented web services for user authentication and information retrieval.
                                    </p>
                                    <p>
                                        Implemented the algorithm Journal Descriptor Indexing and other algorithms
                                        based on vector indexing technique and thesaurus. Responsible for configuration
                                        of the Collexis tool.
                                    </p>
                                    <p>
                                        Developed server and client side for The Cochrane Library and the Portal of
                                        Journals on Health Sciences.
                                    </p>
                                    <p>
                                        Responsible for the total Software Development Life Cycle of&nbsp;
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://decs.bvsalud.org/en/"
                                        >DeCS - Health Sciences Descriptors</a>, i.e. conducting client needs
                                        assessment, architecture,  development, building, installation, and testing.
                                        Please check the articles in English and Portuguese about this tool:
                                        <br />
                                        • <a target="_blank" rel="noopener noreferrer" href="http://www.scielo.br/pdf/acb/v19n2/en_v19n2a13.pdf">
                                        Research in Health area: 1 - DeCS Database (Descriptors in Health Sciences)
                                    </a>
                                        <br />
                                        • <a target="_blank" rel="noopener noreferrer" href="http://www.scielo.br/scielo.php?script=sci_arttext&pid=S0102-86502004000200013">
                                        Pesquisa na área da saúde: 1 - base de dados DeCS (Descritores em Ciências da Saúde)
                                    </a>
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>UNDP - United Nations</h3>
                                    <p className="info">Consultant <span>|</span> Brasília, Brazil <span>|</span> <em className="date">2002</em></p>
                                    <p>
                                        Worked for the United Nations Development Programme (UNDP) as a consultant at
                                        the Ministry of the Environment. Evaluated the Brazilian Net Information about
                                        Biodiversity (BINBR) making an analysis of the information architecture,
                                        hardware, access statistics and database. The aim of this report was to gather
                                        details that later would be used by the Ministry of Environment in its
                                        decision regarding the future of the project.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>University of Manchester (UK)</h3>
                                    <p className="info">Software Developer <span>|</span> Manchester, England <span>|</span> <em className="date">1998</em></p>
                                    <p>
                                        Migrated a <a target="_blank" rel="noopener noreferrer" href="https://www.manchester.ac.uk/">MAVIS</a> module
                                        from a version based on software AVS5 to a version based on software AVS/Express.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>Biological Computing - UNICAMP</h3>
                                    <p className="info">Researcher <span>|</span> Campinas, Brazil <span>|</span> <em className="date">1996 - 1998</em></p>
                                    <p>
                                        At State University of Campinas (Unicamp) I had a two year academic project&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="http://www.bv.fapesp.br/pt/bolsas/46122/algoritmos-para-comparacao-de-sequencias-atraves-do-www/">scholarship</a>,
                                        whose aim was to create algorithms to solve problems in the comparison of character sequences
                                        with a general formulation and available on the web. It was an&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="http://www.iq.usp.br/setubal/formerMembers.html">academic project</a>&nbsp;
                                        and my supervisor was <a target="_blank" rel="noopener noreferrer" href="http://www.iq.usp.br/setubal/index-en.html">Professor Jo&atilde;o Carlos Setubal</a>.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>IMECC - UNICAMP</h3>
                                    <p className="info">Researcher <span>|</span> Campinas, Brazil <span>|</span> <em className="date">1992</em></p>
                                    <p>
                                        At State University of Campinas (Unicamp) I received a&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="https://bv.fapesp.br/pt/bolsas/49338/introducao-as-logicas-paraconsistentes-teoria-e-aplicacoes/">scholarship</a>&nbsp;
                                        to work on a one-year academic project whose aim was to study&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Paraconsistent_logic">Paraconsistent Logic</a>,&nbsp;
                                        from both a theoretical and a practical point of view. As part of the project, I also studied&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Computability">Computability</a>&nbsp;
                                        (using the book&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/Computability-Computable-Functions-Foundations-Mathematics/dp/098155072X">
                                            <em>Computability: Computable Functions, Logic, and the Foundations of Mathematics</em>
                                        </a>
                                        ),&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Set_theory">Set Theory</a>,&nbsp;
                                        and <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Classical_logic">Classical Logic</a>.
                                        My supervisor, who is also one of authors of the aforementioned book, was&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Walter_Carnielli">Professor Walter Carnielli</a>.
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div> {/* item end */}

                        </div> {/* main-col end */}
                    </div> {/* End Work */}

                    {/* Skills ----------------------------------------------- */}
                    {/*
                    <div className="row skill">
                        <div className="three columns header-col">
                            <h1><span>Skills</span></h1>
                        </div>
                        <div className="nine columns main-col">
                            <p>
                                Bellow are some of my skills displayed in a way that
                                shows how comfortable I feel with each of them:
                            </p>
                            <div className="bars">
                                <ul className="skills">
                                    <li><span className="bar-expand java" /><em>Java</em></li>
                                    <li><span className="bar-expand javascript" /><em>JavaScript</em></li>
                                    <li><span className="bar-expand shell-scripting" /><em>Shell Scripting</em></li>
                                    <li><span className="bar-expand micro-services" /><em>Micro Services</em></li>
                                    <li><span className="bar-expand spring-boot" /><em>Spring Boot</em></li>
                                    <li><span className="bar-expand android" /><em>Android</em></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    */}

                </section> {/* Resume Section End*/}
            </React.Fragment>
        );
    }
}
